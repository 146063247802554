"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KakaoAuthErrorMessage = void 0;
var KakaoAuthErrorMessage;
(function (KakaoAuthErrorMessage) {
    KakaoAuthErrorMessage["DUPLICATED_CELL_PHONE"] = "\uAC19\uC740 \uC804\uD654\uBC88\uD638\uB85C \uAC00\uC785 \uC774\uB825\uC774 \uC788\uC2B5\uB2C8\uB2E4. \uC774\uBA54\uC77C \uB610\uB294 \uC560\uD50C\uC544\uC774\uB514\uB85C \uB85C\uADF8\uC778\uC744 \uC2DC\uB3C4\uD574 \uC8FC\uC138\uC694.";
    KakaoAuthErrorMessage["INVALID_CELL_PHONE"] = "\uC720\uD6A8\uD558\uC9C0 \uC54A\uC740 \uC804\uD654\uBC88\uD638\uC785\uB2C8\uB2E4.";
    KakaoAuthErrorMessage["INVALID_CLIENT"] = "invalid_client";
    KakaoAuthErrorMessage["INVALID_GRANT"] = "invalid_grant";
    KakaoAuthErrorMessage["MISSING_PERSONAL_INFORMATION"] = "\uCE74\uCE74\uC624 \uB85C\uADF8\uC778\uC5D0 \uD544\uC694\uD55C \uAC1C\uC778\uC815\uBCF4\uAC00 \uC874\uC7AC\uD558\uC9C0 \uC54A\uC2B5\uB2C8\uB2E4.";
    KakaoAuthErrorMessage["UNKNOWN_ERROR"] = "\uC54C \uC218 \uC5C6\uB294 \uC624\uB958\uAC00 \uBC1C\uC0DD\uD588\uC2B5\uB2C8\uB2E4.";
})(KakaoAuthErrorMessage = exports.KakaoAuthErrorMessage || (exports.KakaoAuthErrorMessage = {}));

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupHistoryType = void 0;
var GroupHistoryType;
(function (GroupHistoryType) {
    GroupHistoryType["Cancel"] = "cancel";
    GroupHistoryType["Modify"] = "modify";
    GroupHistoryType["Memo"] = "memo";
    GroupHistoryType["LunchSms"] = "lunch_sms";
})(GroupHistoryType = exports.GroupHistoryType || (exports.GroupHistoryType = {}));

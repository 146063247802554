"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PARK_TARGET_TYPE_MAP = exports.ParkTargetType = void 0;
var ParkTargetType;
(function (ParkTargetType) {
    ParkTargetType["Sales"] = "sales";
    ParkTargetType["Entrance"] = "entrance";
})(ParkTargetType = exports.ParkTargetType || (exports.ParkTargetType = {}));
exports.PARK_TARGET_TYPE_MAP = {
    [ParkTargetType.Sales]: '매출',
    [ParkTargetType.Entrance]: '객수',
};

import { useCallback } from 'react';

const useDeviceDetect = () => {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const getMobileDetect = useCallback((userAgent: string) => {
    const isAndroid = () => Boolean(userAgent.match(/Android/i));
    const isIOS = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i));
    const isMobile = () => Boolean(isAndroid() || isIOS());
    const isSSR = () => Boolean(userAgent.match(/SSR/i));
    const isDesktop = () => Boolean(!isMobile() && !isSSR());
    const isApp = () => Boolean(userAgent.match(/sportsmonster/i));

    return { isMobile, isAndroid, isIOS, isSSR, isDesktop, isApp };
  }, []);

  return getMobileDetect(userAgent);
};

export default useDeviceDetect;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DASH_PARK_SORTED = exports.PARK_UID_EN_MAP = exports.PARK_UID_MAP = exports.ParkUid = void 0;
var ParkUid;
(function (ParkUid) {
    ParkUid["Daejeon"] = "RWQ277472";
    ParkUid["Hanam"] = "RKZ625564";
    ParkUid["Goyang"] = "RAR635265";
    ParkUid["Anseong"] = "RFS464324";
    ParkUid["Suwon"] = "RQY276279";
    ParkUid["Shooting"] = "RHQ676796";
})(ParkUid = exports.ParkUid || (exports.ParkUid = {}));
exports.PARK_UID_MAP = {
    [ParkUid.Anseong]: '안성점',
    [ParkUid.Daejeon]: '대전점',
    [ParkUid.Goyang]: '고양점',
    [ParkUid.Hanam]: '하남점',
    [ParkUid.Suwon]: '수원점',
    [ParkUid.Shooting]: '슈팅점',
};
exports.PARK_UID_EN_MAP = {
    [ParkUid.Anseong]: 'anseong',
    [ParkUid.Daejeon]: 'daejeon',
    [ParkUid.Goyang]: 'goyang',
    [ParkUid.Hanam]: 'hanam',
    [ParkUid.Suwon]: 'suwon',
    [ParkUid.Shooting]: 'shooting',
};
exports.DASH_PARK_SORTED = [
    ParkUid.Hanam,
    ParkUid.Goyang,
    ParkUid.Anseong,
    ParkUid.Daejeon,
    ParkUid.Suwon,
    ParkUid.Shooting,
];

import { useRouter } from 'next/router';
import Image from 'next/image';
import styled from '@emotion/styled';
import { Chip, Divider, Typography, Stack } from '@mui/material';
import { Color } from '../../constants';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import SmobLogo from '../../public/logo-white.webp';
import SmobText from '../../public/smob-desktop-text.webp';
import { KAKAO_CHAT_URL, SMOB_INSTAGRAM_URL, SMOB_YOUTUBE_URL } from '../../public/config';
import { useBridgeStore } from '@/providers/BridgeProvider';

const Footer = () => {
  const router = useRouter();
  const { isApp } = useDeviceDetect();
  const openExternalLink = useBridgeStore((state) => state.openExternalLink);

  const openLink = (url: string) => {
    if (isApp()) return openExternalLink(url);
    window.open(url);
  };

  return (
    <WebContainer className="pc-footer">
      <div className="pc-branch-bi" onClick={() => router.push('/')}>
        <Image src={SmobLogo} alt="Smob BI" />
      </div>
      <div className="pc-promo">
        <Image src={SmobText} alt="Smon Desc" />
      </div>

      <WebFooter>
        <Stack direction="row">
          <Typography variant={'body1'} fontWeight={'bold'} mr={2} display={'flex'} alignItems={'center'}>
            단체 문의
          </Typography>
          <Chip
            label={
              <Typography fontSize={14} fontWeight={600}>
                카카오톡
              </Typography>
            }
            clickable
            size="small"
            sx={{ borderRadius: 1, bgcolor: Color.background, opacity: 0.5, '&:hover': { bgcolor: 'white' } }}
            onClick={() => openLink(KAKAO_CHAT_URL)}
          />
        </Stack>

        <Stack my={2}>
          <Typography variant={'body1'} fontWeight={'bold'} mb={1}>
            고객센터 <a href="tel:1668-4832">1668-4832</a>
          </Typography>
          <Stack direction="row" columnGap={0.3}>
            <Typography variant={'body2'} color={Color.secondary1}>
              운영시간
            </Typography>
            <Stack direction="row" columnGap={0.3}>
              <Typography variant={'body2'} color={Color.secondary1}>
                평일
              </Typography>
              <Stack>
                <Typography variant={'body2'} color={Color.secondary1}>
                  10:00 ~ 12:00
                </Typography>
                <Typography variant={'body2'} color={Color.secondary1}>
                  13:30 ~ 17:00
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Typography variant={'body2'} color={Color.secondary1}>
            이메일(고객전용) : <b>smob_cs@smob.co.kr</b>
          </Typography>
        </Stack>

        <Stack my={2}>
          <Typography variant={'body1'} fontWeight={'bold'} mb={1}>
            제휴/대관 문의
          </Typography>
          <Typography variant={'body2'} color={Color.secondary1}>
            이메일 : <b>hello@smob.co.kr</b>
          </Typography>
        </Stack>

        <Stack direction={'row'} my={1} gap={3}>
          <Icon onClick={() => router.push(SMOB_INSTAGRAM_URL)}>
            <Image src={'/instagram-icon-white.svg'} width={24} height={24} alt="instagram-icon" />
          </Icon>
          <Icon onClick={() => router.push(SMOB_YOUTUBE_URL)}>
            <Image src={'/youtube-icon-white.svg'} width={24} height={24} alt="youtube-icon" />
          </Icon>
        </Stack>

        <Stack direction={'row'} my={2}>
          <Term onClick={() => router.push('/terms/service')}>서비스 이용약관</Term>
          <Divider
            variant="middle"
            orientation="vertical"
            sx={{ height: 12, mx: 1.2, my: 0.1, bgcolor: Color.background, opacity: 0.5 }}
          />
          <Term onClick={() => router.push('/terms/privacy')}>개인정보처리방침</Term>
          <Divider
            variant="middle"
            orientation="vertical"
            sx={{ height: 12, mx: 1.2, my: 0.1, bgcolor: Color.background, opacity: 0.5 }}
          />
          <Term onClick={() => router.push('/terms/park')}>파크 이용약관</Term>
        </Stack>

        <Stack>
          <Typography variant={'body2'} color={Color.secondary1}>
            (주)스몹컴퍼니 대표이사 박세효
          </Typography>
          <Typography variant={'body2'} color={Color.secondary1}>
            주소: 경기도 성남시 분당구 대왕판교로 660
          </Typography>
          <Typography variant={'body2'} color={Color.secondary1}>
            사업자등록번호: 214-87-97600
          </Typography>
          <Typography variant={'body2'} color={Color.secondary1}>
            통신판매업신고: 제2022-성남분당-0799호
          </Typography>
          <Typography variant={'body2'} color={Color.secondary1}>
            특허출원번호: 10-2017-0115016
          </Typography>
        </Stack>
      </WebFooter>
    </WebContainer>
  );
};

export default Footer;

const WebContainer = styled.section`
  @media only screen and (min-width: 1400px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 160px;
    padding-bottom: 90px;
    position: fixed;
    min-height: 100vh;
    left: max(calc(50% - 33vw), calc(50% - 720px));
    height: calc(100% - 250px);
    z-index: 10;

    .pc-branch-bi {
      cursor: pointer;
    }

    .pc-promo {
      animation: 0.5s ease-out 0.2s both smoothAppearUpY;
    }
  }

  @media only screen and (max-width: 1400px) {
    display: none;
    visibility: hidden;
  }

  @media only screen and (max-height: 960px) {
    row-gap: 50px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const WebFooter = styled.section`
  color: rgba(255, 255, 255, 0.4);
  font-weight: 300;
  font-size: 12px;
`;

const Term = styled.span`
  cursor: pointer;
  &:hover {
    color: #fff;
  }
`;

const Icon = styled.div`
  cursor: pointer;
`;
